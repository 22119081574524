import Vue from 'vue';
import InboxMembersAPI from '../../api/inboxMembers';

export const SET_INBOX_MEMBERS_UI_FLAG = 'SET_INBOX_MEMBERS_UI_FLAG';
export const ADD_AGENTS_TO_INBOX = 'ADD_AGENTS_TO_INBOX';

export const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getInboxMembers: (state) => (inboxId) => {
    if (!inboxId) return [];
    const members = state.records[inboxId] || [];
    return members.payload || members;
  },
};

export const actions = {
  get: async ({ commit }, { inboxId }) => {
    if (!inboxId) return;
    commit(SET_INBOX_MEMBERS_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxMembersAPI.get(inboxId);
      commit(ADD_AGENTS_TO_INBOX, { data: response.data, inboxId });
    } catch (error) {
      // Instead of throwing, we'll just log the error
      console.error('Error fetching inbox members:', error);
    } finally {
      commit(SET_INBOX_MEMBERS_UI_FLAG, { isFetching: false });
    }
  },
  show(_, { inboxId }) {
    return InboxMembersAPI.show(inboxId);
  },
  update: async ({ commit }, { inboxId, agentList }) => {
    commit(SET_INBOX_MEMBERS_UI_FLAG, { isUpdating: true });
    try {
      const response = await InboxMembersAPI.update({ inboxId, agentList });
      commit(ADD_AGENTS_TO_INBOX, { data: response.data, inboxId });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INBOX_MEMBERS_UI_FLAG, { isUpdating: false });
    }
  },
  create: async ({ commit }, { inboxId, agentList }) => {
    commit(SET_INBOX_MEMBERS_UI_FLAG, { isUpdating: true });
    try {
      const response = await InboxMembersAPI.update({ inboxId, agentList });
      commit(ADD_AGENTS_TO_INBOX, { data: response.data, inboxId });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INBOX_MEMBERS_UI_FLAG, { isUpdating: false });
    }
  },
};

const mutations = {
  [SET_INBOX_MEMBERS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [ADD_AGENTS_TO_INBOX]($state, { data, inboxId }) {
    Vue.set($state.records, inboxId, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};