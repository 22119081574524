/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class InboxMembers extends CacheEnabledApiClient {
  constructor() {
    super('inbox_members', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  assignableAgents() {
    return this.$store.getters['inboxMembers/getInboxMembers'](this.inboxId);
  }
  
  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox_members';
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: dataToParse };
  }

  get(inboxId) {
    return axios.get(`${this.url}/${inboxId}`);
  }

  getByUserId(userId) {
    return axios.get(`${this.url}?user_id=${userId}`);
  }

  update({ inboxId, agentList }) {
    return axios.patch(this.url, {
      inbox_id: inboxId,
      user_ids: agentList,
    });
  }
}

export default new InboxMembers();
