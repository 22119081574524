import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import Vue from 'vue';
import types from '../mutation-types';
import CustomActionsAPI from '../../api/customActions';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: {},
  uiFlags: {
    isFetchingItem: false,
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isExecuting: false,
  },
};

export const getters = {
  getCustomActions: $state => Object.values($state.records),
  getCustomAction: $state => id => $state.records[id] || {},
  getUIFlags: $state => $state.uiFlags,
};

export const actions = {
  get: async function getCustomActions({ commit }) {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CustomActionsAPI.get();
      commit(types.SET_CUSTOM_ACTIONS, data);
    } catch (error) {
      console.error('Error fetching custom actions:', error);
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isFetching: false });
    }
  },
  getSingleCustomAction: async function getCustomActionById({ commit, getters }, customActionId) {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await CustomActionsAPI.show(customActionId);
      const customActionWithBody = {
        ...response.data,
        body: response.data.body || '',
      };
      commit(types.ADD_CUSTOM_ACTION, customActionWithBody);
      return customActionWithBody;
    } catch (error) {
      console.error('Error fetching custom action:', error);
      throw error;
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isFetchingItem: false });
    }
  },
  create: async function createCustomAction({ commit }, customActionsObj) {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isCreating: true });
    try {
      const response = await CustomActionsAPI.create(customActionsObj);
      const customAction = response.data; // The response is already the custom action object

      if (customAction && customAction.id) {
        commit(types.ADD_CUSTOM_ACTION, customAction);
        return customAction;
      } else {
        throw new Error('Invalid custom action data received');
      }
    } catch (error) {
      throwErrorMessage(error);
      throw error;
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isCreating: false });
    }
  },
  execute: async ({ commit, getters }, { customActionId }) => {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isExecuting: true });
    try {
      const customAction = getters.getCustomAction(customActionId);
      const method = customAction.http_method.toLowerCase();
      const response = await CustomActionsAPI.execute(customActionId, method);
      return {
        data: response.data,
        status: response.status,
        headers: response.headers
      };
    } catch (error) {
      console.error('Error executing custom action:', error);
      throwErrorMessage(error);
      throw error;
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isExecuting: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isUpdating: true });
    try {
      const response = await CustomActionsAPI.update(id, updateObj);
      commit(types.EDIT_CUSTOM_ACTION, response.data);
      return response.data; // Return the updated custom action data
    } catch (error) {
      throwErrorMessage(error);
      throw error; // Re-throw the error to be caught in the component
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isDeleting: true });
    try {
      await CustomActionsAPI.delete(id);
      commit(types.DELETE_CUSTOM_ACTION, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_CUSTOM_ACTIONS_UI_FLAG, { isDeleting: false });
    }
  },
  updateStatus: async ({ commit }, { customActionId, status }) => {
    try {
      const response = await CustomActionsAPI.updateStatus(customActionId, status);
      if (response.data) {
        commit(types.EDIT_CUSTOM_ACTION, response.data);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      throwErrorMessage(error);
      throw error;
    }
  },
};

export const mutations = {
  [types.SET_CUSTOM_ACTIONS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_CUSTOM_ACTION]($state, customAction) {
    if (customAction.http_headers && typeof customAction.http_headers === 'string') {
      try {
        customAction.http_headers = JSON.parse(customAction.http_headers);
      } catch (error) {
        console.error('Error parsing http_headers:', error);
        customAction.http_headers = [];
      }
    }
    customAction.body = customAction.body || '';
    Vue.set($state.records, customAction.id, customAction);
  },
  [types.SET_CUSTOM_ACTIONS]($state, customActions) {
    $state.records = customActions.reduce((acc, customAction) => {
      acc[customAction.id] = customAction;
      return acc;
    }, {});
  },
  [types.EDIT_CUSTOM_ACTION]($state, customAction) {
    if (customAction.http_headers && typeof customAction.http_headers === 'string') {
      try {
        customAction.http_headers = JSON.parse(customAction.http_headers);
      } catch (error) {
        console.error('Error parsing http_headers:', error);
        customAction.http_headers = [];
      }
    }
    customAction.body = customAction.body || '';
    Vue.set($state.records, customAction.id, customAction);
  },
  [types.DELETE_CUSTOM_ACTION]($state, id) {
    Vue.delete($state.records, id);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
