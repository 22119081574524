/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../../helper/URLHelper';

const CreateStepWrap = () => import('./Create/Index.vue');
const CreateCustomAction = () => import('./Create/CreateCustomAction.vue');
const SetupResponse = () => import('./Create/SetupResponse.vue');
const TestRequest = () => import('./Create/TestRequest.vue');
const EditStepWrap = () => import('./Edit/Index.vue');
const EditCustomAction = () => import('./Edit/EditCustomAction.vue');
const SettingsContent = () => import('../Wrapper.vue');
const CustomActionsHome = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/custom-actions'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_custom_actions_list';
        return {
          headerTitle: 'CUSTOM_ACTIONS.HEADER',
          headerButtonText: 'CUSTOM_ACTIONS.HEADER_BTN_TXT',
          icon: 'flash-settings',
          newButtonRoutes: ['settings_custom_actions_new'],
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_custom_actions',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_custom_actions_list',
          component: CustomActionsHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_custom_actions_new',
              component: CreateCustomAction,
              roles: ['administrator'],
            },
            {
              path: ':customActionId/test-request',
              name: 'settings_custom_actions_test_request',
              component: TestRequest,
              roles: ['administrator'],
            },
            {
              path: ':customActionId/setup-response',
              name: 'settings_custom_actions_setup_response',
              roles: ['administrator'],
              component: SetupResponse,
            },
          ],
        },
        {
          path: ':customActionId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_custom_actions_edit',
              component: EditCustomAction,
              roles: ['administrator'],
            },
            {
              path: ':customActionId/test-request',
              name: 'settings_custom_actions_test_request',
              component: TestRequest,
              roles: ['administrator'],
            },
            {
              path: ':customActionId/setup-response',
              name: 'settings_custom_actions_setup_response',
              roles: ['administrator'],
              component: SetupResponse,
            },
          ],
        },
      ],
    },
  ],
};
