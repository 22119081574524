import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
      getTeamMembers: 'teamMembers/getTeamMembers',
      getInboxMembers: 'inboxMembers/getInboxMembers',
      getAllUsers: 'agents/getAgents',
    }),
    isAgentSelected() {
      return this.currentChat?.meta?.assignee;
    },
    createNoneAgent() {
      return {
        confirmed: true,
        name: 'None',
        id: 0,
        role: 'agent',
        account_id: 0,
        email: 'None',
      };
    },
    agentsList() {
      let agents = [];

      // Administrators always get the full list of assignable agents
      if (this.currentUser.role === "administrator") {
        agents = this.getAllUsers || [];
      } else if (this.currentChat?.meta?.team) { // For team chats, get team members
        agents = this.getTeamMembers(this.currentChat.meta.team.id);
      } else { // For non-team chats, get inbox members
        agents = this.getInboxMembers(this.inboxId) || [];
      }
      
      const uniqueAgents = this.removeDuplicateAgents(agents);
      const agentsByUpdatedPresence = this.getAgentsByUpdatedPresence(uniqueAgents);
      const none = this.createNoneAgent;
      const filteredAgentsByAvailability = this.sortedAgentsByAvailability(
        agentsByUpdatedPresence
      );
      const filteredAgents = [
        none,
        ...filteredAgentsByAvailability,
      ];
      return filteredAgents;
    }, 
  },   
  methods: {
    getAgentsByAvailability(agents, availability) {
      return agents
        .filter(agent => agent.availability_status === availability)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedAgentsByAvailability(agents) {
      const onlineAgents = this.getAgentsByAvailability(agents, 'online');
      const busyAgents = this.getAgentsByAvailability(agents, 'busy');
      const offlineAgents = this.getAgentsByAvailability(agents, 'offline');
      const filteredAgents = [...onlineAgents, ...busyAgents, ...offlineAgents];
      return filteredAgents;
    },
    getAgentsByUpdatedPresence(agents) {
      // Here we are updating the availability status of the current user dynamically (live) based on the current account availability status
      const agentsWithDynamicPresenceUpdate = agents.map(item =>
        item.id === this.currentUser.id
          ? {
              ...item,
              availability_status: this.currentUser.accounts.find(
                account => account.id === this.currentAccountId
              ).availability_status,
            }
          : item
      );
      return agentsWithDynamicPresenceUpdate;
    },
    removeDuplicateAgents(agents) {
      const uniqueAgents = [];
      const seenIds = new Set();
      agents.forEach(agent => {
        if (!seenIds.has(agent.id)) {
          uniqueAgents.push(agent);
          seenIds.add(agent.id);
        }
      });
      return uniqueAgents;
    },
  },
};